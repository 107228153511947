export const backendUrl="https://scmadmin.deventa.io"
export const backendRelativePath=""
export let backendURLWithRelativePath=`${backendUrl}${backendRelativePath}`
export let apiURL=`${backendURLWithRelativePath}/api/v2`
export const chatURL="https://scmchat.deventa.io"
// These consts, if available, do manage public sites
// They will access with a token
// and have all the same user (the one, on the backend, which is associated to this token)
// If publicAccess is true, then the other constants become relevant, otherwise, not
export const publicAccess = false
export const accessToken = ""

export const disableChat = false

export const feName = "SCM"
// Mux Com Settings
export const muxMonitorEnvKey = "7ualav8qlm8i808aukkmj5ta3"

export const gtagKey = ""
